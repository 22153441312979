.logo_heading{
    margin-bottom:40px;
    font-size: 35px;
}

.logo_para{
    font-size:20px;
}

@media screen and (max-width:1305px) {
    .header_signin{
        flex-direction: column;
    }
}

@media screen and (max-width:1538px) {
    .header_signup{
        flex-direction: column;
    }
}

@media screen and (max-width:1050px) {
    .logo_heading{
        font-size: 27px;
    }
    .logo_para{
        font-size:20px;
    }
}

@media screen and (max-width:900px) {
    .logo_heading{
        font-size: 19px;
    }
    .logo_para{
        font-size:15px;
    }
}

@media screen and (max-width:920px) {
    .transparent_container{
        display: none !important;
    }

    .container{
        grid-template-columns: 0% 100%;
        background-image: url(/src/image/logo/loginPageLogo.jpg);
        background-repeat: no-repeat;
        padding: 20px 0;
    }
}

@media screen and (max-width:500px) {
    .form_container{
        width: 90%;
    }
}

@media screen and (max-width:640px) {
    .form_container_email_verification{
        width: 90%;
    }
}