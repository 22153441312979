.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.ml-1 {
    margin-left: 0.5rem;
}

.ml-2 {
    margin-left: 0.75rem;
}

.ml-3 {
    margin-left: 1rem;
}

.ml-4 {
    margin-left: 1.125rem;
}

.mr-1 {
    margin-right: 0.5rem;
}

.mr-2 {
    margin-right: 0.75rem;
}

.mr-3 {
    margin-right: 1rem;
}

.mr-4 {
    margin-right: 1.125rem;
}

.mb-1 {
    margin-bottom: 0.5rem;
}

.mb-2 {
    margin-bottom: 0.75rem;
}

.mb-3 {
    margin-bottom: 1rem;
}

.mb-4 {
    margin-bottom: 1.125rem;
}

#charger  .MuiGrid-container,
#authcodes .MuiGrid-container,
#pricegroup .MuiGrid-container,
#profile .MuiGrid-container,
#team .MuiGrid-container,
#company .MuiGrid-container
 {
    margin-bottom: 2rem;
}

#charger .MuiGrid-item h6,
#authcodes .MuiGrid-item h6,
#pricegroup .MuiGrid-item h6,
#profile .MuiGrid-item h6,
#team .MuiGrid-item h6, 
#company .MuiGrid-container h6{
    margin-bottom: 0.925rem;
    text-transform: uppercase;
}

.MuiDialog-paper {
    width: -webkit-fill-available;
}

#reset-password img {
    width: 200px;
    margin: 0 auto;
    margin-bottom: 1.5rem;
}

#reset-password .reset-password__main .MuiCard-root {
    border-radius: 5px;
    box-shadow: none;
}

#reset-password 
.reset-password__main 
.MuiCardContent-root form > *:not(:last-child),
#change-password > *:not(:last-child)
{
    margin-bottom: 1rem;
}

#reset-password .reset-password__main h4 {
    margin-bottom: 0.3rem !important;
}

#reset-password 
.reset-password__main 
.MuiTypography-caption:not(.username) {
    font-size: 0.9em;
    font-weight: 500;
}

/* google map */
.map-container {
    height: 500px;
    width: 100%;
}

.MuiChip-filled.checked {
    background: #00AB55;
    color: white;
    box-shadow: 0 1px 4px 0 #bdbdbd;
}

#price-type {
    width: 12%;
}

.time-range > div:nth-child(2n) {
    margin-bottom: 0.525rem;
}

.time-range.fixed {
    margin-bottom: 0 !important;
}

.day-fieldset {
    padding: 0.825rem;
    border-color: #cecece73;
    border-width: 1px;
}

.day-fieldset:not(:first-child) {
    margin-top: 1.525rem;
}

.day-fieldset > legend {
    padding: 0 8px;
    margin-left: 0.825rem;
}

.day-checkbox {
    border: 1px solid #80808085;
    padding: 0 1rem;
    border-radius: 4px;
}

.price-group-modal .day-fieldset {
    padding-bottom: 2rem;
}

.price-group-modal .switch-all {
    margin: 0 50px;
}

.day-checkbox > span:last-child {
    width: 120px;
}

@media screen and (min-width: 320px) {
    .price-group-modal {
        width: 350px;
    }

    .day-fieldset + .MuiBox-root button {
        width: 100%;
    }
}

@media screen and (min-width: 768px) {
    .price-group-modal {
        width: 700px;
    }

    .day-fieldset + .MuiBox-root button {
        min-width: 64px;
        width: 30%;
    }
}


/* Register */
.text-muted {
    color: #6c757d !important;
}

small {
    font-size: .75em;
}

#company .show-all-chargers label {
    margin-left: 0;
    margin-bottom: 1rem;
}